import React from 'react'
import SEO from '../components/generic/Seo'
import Teaser from '../components/artboxone/Teaser'
import { useStaticQuery, graphql , Link } from 'gatsby'
import { AlternateUrl, LandingPage } from '../types'
import { useTranslation } from 'react-i18next'
import { withUrlSlugs } from '../components/generic/withUrlSlugs'
import {
  buildAlternativeUrlsForLps,
  mapInternalKey2Slug,
} from '../utils/UrlUtils'
import Edit from '../components/cms/Edit'
import Helmet from 'react-helmet'
import {env} from '../../environment'

const Sitemap = (options: any) => {
  const data = useStaticQuery(graphql`
    query SitemapLandingpages {
      allCockpitLandingpages(filter: { published: { value: { eq: true } } }) {
        nodes {
          lang
          cockpitId
          url {
            value
          }
          pagetitle {
            value
          }
        }
      }
    }
  `)

  const { t } = useTranslation('translation')

  const landingPages: any = {
    poster: [],
    wallart: [],
    further_products: [],
    gifts: [],
    inspiration: [],
    info: [],
    etc: [],
  }

  if (env.getCmsLanguage() == 'de') {
    landingPages.wallart.push({
      url: '/wandbilder',
      title: 'Alle Wandbilder',
      cockpitId: '',
    })

    let materials = [
      'poster',
      'poster_plastic',
      'poster_metal_frame',
      'poster_wooden_frame',
    ]

    materials.map((key: string) => {
      landingPages.poster.push({
        url: '/wandbilder/' + mapInternalKey2Slug(key) + '/alle/bestseller',
        title: t(key),
        cockpitId: '',
      })
    })

    materials = [
      'canvas',
      'alu',
      'acryl',
      'galleryprint',
      'wooden',
      'metalposter',
    ]

    materials.map((key: string) => {
      landingPages.wallart.push({
        url: '/wandbilder/' + mapInternalKey2Slug(key) + '/alle/bestseller',
        title: t(key),
        cockpitId: '',
      })
    })

    landingPages.further_products.push({
      url: '/geschenke/tassen/alle/bestseller',
      title: 'Tassen',
      cockpitId: '',
    })
    landingPages.further_products.push({
      url: '/geschenke/puzzle/alle/bestseller',
      title: 'Puzzle',
      cockpitId: '',
    })

    landingPages.further_products.push({
      url: '/geschenke/kissen/alle/bestseller',
      title: 'Kissen',
      cockpitId: '',
    })

    landingPages.further_products.push({
      url: '/kalender/wandkalender/alle/bestseller',
      title: 'Wandkalender',
      cockpitId: '',
    })

    landingPages.further_products.push({
      url: '/geschenke/handyhuellen',
      title: 'Handyhüllen',
      cockpitId: '',
    })

    landingPages.further_products.push({
      url: '/geschenke/handyhuellen/apple',
      title: 'Apple Handyhüllen',
      cockpitId: '',
    })

    landingPages.further_products.push({
      url: '/geschenke/handyhuellen/samsung',
      title: 'Samsung Handyhüllen',
      cockpitId: '',
    })
  } else if (env.getCmsLanguage() == 'en') {
    landingPages.wallart.push({
      url: '/wall-art',
      title: 'All wall art',
      cockpitId: '',
    })

    let materials = [
      'poster',
      'poster_plastic',
      'poster_metal_frame',
      'poster_wooden_frame',
    ]

    materials.map((key: string) => {
      landingPages.poster.push({
        url: '/wall-art/' + mapInternalKey2Slug(key) + '/all/bestseller',
        title: t(key),
        cockpitId: '',
      })
    })

    materials = ['canvas', 'alu', 'galleryprint', 'wooden']

    materials.map((key: string) => {
      landingPages.wallart.push({
        url: '/wall-art/' + mapInternalKey2Slug(key) + '/all/bestseller',
        title: t(key),
        cockpitId: '',
      })
    })
  }

  data.allCockpitLandingpages.nodes
    .filter((node: any) => {
      const _url = node.url.value

      if (['/mbo-test', '/fashion', '/mbo-test-zwei'].indexOf(_url) >= 0) {
        return false
      } else {
        return node.lang == env.getCmsLanguage()
      }
    })
    .map((node: any) => {
      let title = node.pagetitle?.value

      if (title === '') {
        title = node.url.value
      } else {
        title = title
      }

      const lpObject = {
        url: node.url.value,
        title: title,
        cockpitId: node.cockpitId,
      }

      let key = 'etc'

      if (
        node.url.value.indexOf('/geschenkideen/') === 0 ||
        node.url.value.indexOf('/gift-ideas/') === 0
      ) {
        key = 'gifts'
      } else if (
        node.url.value.indexOf('/informationen/') === 0 ||
        node.url.value.indexOf('/infos/') === 0
      ) {
        key = 'info'
      } else if (node.url.value.indexOf('/inspiration/') === 0) {
        key = 'inspiration'
      } else if (
        node.url.value.indexOf('/wandbilder/') === 0 ||
        node.url.value.indexOf('/wall-art/') === 0
      ) {
        key = 'wallart'
      }

      let ignore = false

      if (node.url.value == '/nl_anmeldung_erfolgreich') {
        ignore = true
      }

      if (!ignore) {
        landingPages[key].push(lpObject)
      }
    })

  const canonicals: any = []
  canonicals.push(
    <link
      href={env.getBaseUrl() + options.path}
      key="canonical-lp"
      rel="canonical"
    />,
  )

  buildAlternativeUrlsForLps('/', {
    de: '/informationen/sitemap',
    en: '/infos/sitemap',
    ch: '/informationen/sitemap',
  }).map((alternateUrl: AlternateUrl) => {
    canonicals.push(
      <link
        href={alternateUrl.url}
        hrefLang={alternateUrl.locale}
        key={'alternate-' + alternateUrl.url}
        rel="alternate"
      />,
    )
  })

  return (
    <>
      <Helmet>{canonicals.map((canonical: any) => canonical)}</Helmet>
        <SEO title="artboxONE Sitemap" />

        <div className="container">
          <Teaser
            body={t('Hier eine Übersicht der Einstiegsseiten')}
            head="Sitemap"
          />
          {Object.keys(landingPages).map((key: string) => (
            <>
              {landingPages[key].length > 0 && (
                <>
                  <h2>{t(key)}</h2>
                  <ul>
                    {landingPages[key].map((page: LandingPage) => (
                      <li>
                        <Link to={page.url}>{page.title}</Link>
                        <Edit cockpitId={page.cockpitId} type="Landingpages" />
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </>
          ))}
        </div>
    </>
  )
}

export default withUrlSlugs(Sitemap)
